import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: t('Docs'),
        href: 'https://docs.ripae.finance',
        isHighlighted: true,
      },
      {
        label: t('Brand'),
        href: 'https://docs.ripae.finance/marketing-profile',
      },
      {
        label: t('Blog'),
        href: 'https://ripaefinance.medium.com/',
      },
      // {
      //   label: t('Community'),
      //   href: 'https://docs.pancakeswap.finance/contact-us/telegram',
      // },
      // {
      //   label: t('Litepaper'),
      //   href: 'https://v2litepaper.pancakeswap.finance/',
      // },
      {
        label: '—',
      },
      {
        label: t('Online Store'),
        href: 'https://swag.ripae.finance/',
      },
    ],
  },
  {
    label: t('Help'),
    items: [
      // {
      //   label: t('Customer Support'),
      //   href: 'https://docs.pancakeswap.finance/contact-us/customer-support',
      // },
      {
        label: t('Troubleshooting'),
        href: 'https://docs.ripae.finance/faq',
      },
      {
        label: t('Guides'),
        href: 'https://docs.ripae.finance/guides',
      },
    ],
  },
  {
    label: t('Developers'),
    items: [
      {
        label: 'Github',
        href: 'https://github.com/ripaefinance',
      },
      {
        label: t('Documentation'),
        href: 'https://docs.ripae.finance/',
      },
      // {
      //   label: t('Bug Bounty'),
      //   href: 'https://docs.pancakeswap.finance/code/bug-bounty',
      // },
      {
        label: t('Audits'),
        href: 'https://docs.ripae.finance/protocol/security',
      },
      // {
      //   label: t('Careers'),
      //   href: 'https://docs.pancakeswap.finance/hiring/become-a-chef',
      // },
    ],
  },
]
