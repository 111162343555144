var ref, ref1, ref2, ref3, ref4, ref5, ref6;
import Metamask from "../../components/Svg/Icons/Metamask";
import WalletConnect from "../../components/Svg/Icons/WalletConnect";
import TrustWallet from "../../components/Svg/Icons/TrustWallet";
import MathWallet from "../../components/Svg/Icons/MathWallet";
import TokenPocket from "../../components/Svg/Icons/TokenPocket";
import BinanceChain from "../../components/Svg/Icons/BinanceChain";
import SafePal from "../../components/Svg/Icons/SafePal";
import Coin98 from "../../components/Svg/Icons/Coin98";
import Blocto from "../../components/Svg/Icons/Blocto";
import Brave from "../../components/Svg/Icons/Brave";
import CoinbaseWallet from "../../components/Svg/Icons/CoinbaseWallet";
import Opera from "../../components/Svg/Icons/Opera";
import { ConnectorNames } from "./types";
var connectors = [
    {
        title: "Metamask",
        icon: Metamask,
        connectorId: ConnectorNames.MetaMask,
        priority: 1,
        href: "https://metamask.app.link/dapp/pancakeswap.finance/"
    },
    {
        title: "Binance Wallet",
        icon: BinanceChain,
        connectorId: ConnectorNames.BSC,
        priority: 2
    },
    {
        title: "Coinbase Wallet",
        icon: CoinbaseWallet,
        connectorId: ConnectorNames.WalletLink,
        priority: 3
    },
    {
        title: "Trust Wallet",
        icon: TrustWallet,
        connectorId: ConnectorNames.Injected,
        priority: 4,
        href: "https://link.trustwallet.com/open_url?coin_id=20000714&url=https://pancakeswap.finance/"
    },
    {
        title: "WalletConnect",
        icon: WalletConnect,
        connectorId: ConnectorNames.WalletConnect,
        priority: 5
    },
    {
        title: "Opera Wallet",
        icon: Opera,
        connectorId: ConnectorNames.Injected,
        priority: function() {
            var ref;
            return "object" !== "undefined" && Boolean((ref = window.ethereum) === null || ref === void 0 ? void 0 : ref.isOpera) ? 0 : 6;
        },
        installed: "object" !== "undefined" && Boolean((ref = window.ethereum) === null || ref === void 0 ? void 0 : ref.isOpera),
        href: "https://www.opera.com/crypto/next"
    },
    {
        title: "Brave Wallet",
        icon: Brave,
        connectorId: ConnectorNames.Injected,
        priority: function() {
            var ref;
            return "object" !== "undefined" && Boolean((ref = window.ethereum) === null || ref === void 0 ? void 0 : ref.isBraveWallet) ? 0 : 6;
        },
        installed: "object" !== "undefined" && Boolean((ref1 = window.ethereum) === null || ref1 === void 0 ? void 0 : ref1.isBraveWallet)
    },
    {
        title: "MathWallet",
        icon: MathWallet,
        connectorId: ConnectorNames.Injected,
        // @ts-ignore
        installed: "object" !== "undefined" && Boolean((ref2 = window.ethereum) === null || ref2 === void 0 ? void 0 : ref2.isMathWallet),
        priority: function() {
            var ref;
            // @ts-ignore
            return "object" !== "undefined" && Boolean((ref = window.ethereum) === null || ref === void 0 ? void 0 : ref.isMathWallet) ? 0 : 999;
        }
    },
    {
        title: "TokenPocket",
        icon: TokenPocket,
        connectorId: ConnectorNames.Injected,
        priority: function() {
            var ref;
            return "object" !== "undefined" && Boolean((ref = window.ethereum) === null || ref === void 0 ? void 0 : ref.isTokenPocket) ? 0 : 999;
        },
        installed: "object" !== "undefined" && Boolean((ref3 = window.ethereum) === null || ref3 === void 0 ? void 0 : ref3.isTokenPocket)
    },
    {
        title: "SafePal",
        icon: SafePal,
        connectorId: ConnectorNames.Injected,
        // @ts-ignore
        installed: "object" !== "undefined" && Boolean((ref4 = window.ethereum) === null || ref4 === void 0 ? void 0 : ref4.isSafePal),
        priority: function() {
            var ref;
            // @ts-ignore
            return "object" !== "undefined" && Boolean((ref = window.ethereum) === null || ref === void 0 ? void 0 : ref.isSafePal) ? 0 : 999;
        }
    },
    {
        title: "Coin98",
        icon: Coin98,
        connectorId: ConnectorNames.Injected,
        // @ts-ignore
        installed: "object" !== "undefined" && (Boolean((ref5 = window.ethereum) === null || ref5 === void 0 ? void 0 : ref5.isCoin98) || Boolean(window.coin98)),
        priority: function() {
            var ref;
            // @ts-ignore
            return "object" !== "undefined" && (Boolean((ref = window.ethereum) === null || ref === void 0 ? void 0 : ref.isCoin98) || Boolean(window.coin98)) ? 0 : 999;
        }
    },
    {
        title: "Blocto",
        icon: Blocto,
        connectorId: ConnectorNames.Injected,
        // @ts-ignore
        installed: "object" !== "undefined" && Boolean((ref6 = window.ethereum) === null || ref6 === void 0 ? void 0 : ref6.isBlocto),
        priority: function() {
            var ref;
            // @ts-ignore
            return "object" !== "undefined" && Boolean((ref = window.ethereum) === null || ref === void 0 ? void 0 : ref.isBlocto) ? 0 : 999;
        }
    }, 
];
export default connectors;
export var connectorLocalStorageKey = "connectorIdv2";
export var walletLocalStorageKey = "wallet";
export var walletConnectConfig = connectors.find(function(c) {
    return c.title === "WalletConnect";
});
